import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { API } from '../../constants'
import client from '../../helpers/feathers'
import useNotificationContext from '../../hooks/useNotificationContext'
import styles from './LoginForm.module.scss'

interface LoginFormProps {
    redirect?: string
}

const LoginForm = ({ redirect = '/account' }: LoginFormProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { addNotification } = useNotificationContext()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | JSX.Element>('')
    const [is2fa, setIs2fa] = useState(false)
    const [token, setToken] = useState('')

    useEffect(() => {
        if (error !== '')
            addNotification({
                id: new Date().getTime().toString(36),
                text: error
            })
    }, [error])

    const changeHandler = (dispatch: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement> => {
        return (event) => {
            setError('')
            dispatch(event.target.value)
        }
    }

    const resendActivate = () => {
        fetch(`${API}/activate?email=${email}`)
            .then(() => addNotification({
                id: new Date().getTime().toString(36),
                text: t('otpravleno')
            }))
    }

    const send = () => {
        client.authenticate({
            strategy: 'local',
            email,
            password,
            token
        }).then((res: any) => {

            localStorage.setItem('permissions', JSON.stringify(res.user.roles))
            navigate(redirect)
        }).catch((e: Error) => {
            switch (e.message) {
                case 'Token required.':
                case 'Invalid token.':
                    setIs2fa(true)
                    break;
                case 'no active':
                    setError(<>
                        {t('vasha-uchetnaya-zapis-ne-aktivirovanna-chto-by-aktivirovat-pereidite-po-ssylke-v-pisme')}<br />
                        <a href="#" onClick={resendActivate}>{t('otpravit-povtorno')}</a>
                    </>)
                    break;

                default:
                    setError(t('nekorrektnye-dannye'))
                    if (!is2fa)
                        setPassword('')
                    break;
            }
        }
        )
    }

    return (
        <Row>
            {!is2fa ?
                <>
                    <Col xs={12}>
                        <input
                            type="email"
                            className={`${styles.input} ${error ? styles.error : ''}`}
                            placeholder={t('email')}
                            value={email}
                            onChange={changeHandler(setEmail)}
                        />
                    </Col>
                    <Col xs={12}>
                        <input
                            type="password"
                            className={`${styles.input} ${error ? styles.error : ''}`}
                            placeholder={t('parol')}
                            value={password}
                            onChange={changeHandler(setPassword)}
                        />
                    </Col>
                </>
                :
                <Col xs={12}>
                    <input
                        type="text"
                        className={`${styles.input} ${error ? styles.error : ''}`}
                        placeholder={t('kod-podtverzhdeniya')}
                        value={token}
                        onChange={changeHandler(setToken)}
                    />
                </Col>
            }
            <Col xs={12}>
                <button
                    className="btn-green"
                    style={{ width: '100%' }}
                    onClick={send}
                >
                    {t('voiti')}
                </button>
            </Col>
            <Col xs={12}>
                <p>{t('net-akkaunta')} <Link to={'/register'}>{t('zaregistrirovatsya')}</Link></p>
                <p>{t('zabyli-parol')} <Link to={'/reset'}>{t('vosstanovit')}</Link></p>
            </Col>

        </Row>
    )
}

export default LoginForm