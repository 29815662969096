import { AutocompleteInput, BooleanField, BooleanInput, Create, Datagrid, DateField, DeleteWithConfirmButton, Edit, EditButton, FormTab, FunctionField, List, NumberField, NumberInput, Pagination, ReferenceField, ReferenceInput, ReferenceManyField, Resource, ResourceElement, ResourceProps, SaveButton, SearchInput, SelectField, SelectInput, Show, ShowButton, Tab, TabbedForm, TabbedShowLayout, TextField, TextInput, Toolbar, ToolbarProps, TransformData, useListContext, useRecordContext } from "react-admin"
import { TRANSACTION_STATUSES, TRANSACTION_TYPES } from "../constants";
import PersonIcon from '@mui/icons-material/Person';
import { useEffect, useState } from "react";
import useStructure from "../hooks/useStructure";
import { Paginated } from "@feathersjs/feathers";
import { IUser } from "../interfaces/services";

const RESOURCE = 'users'

const ROLES = [
    {
        id: 'user',
        name: 'user'
    },
    {
        id: 'admin',
        name: 'admin'
    }
]

const RANKS = [
    {
        id: 'Стажер',
        name: 'Стажер'
    },
    {
        id: 'Менеджер',
        name: 'Менеджер'
    },
    {
        id: 'Руководитель',
        name: 'Руководитель'
    },
    {
        id: 'Директор 1 уровня',
        name: 'Директор 1 уровня'
    },
    {
        id: 'Директор 2 уровня',
        name: 'Директор 2 уровня'
    }
]

const postFilters = [
    <SearchInput source="email.$search" alwaysOn />,
    <SelectInput source="roles" choices={ROLES} alwaysOn />,
    <SelectInput source="rank" choices={RANKS} alwaysOn />
];

const filterToQuery = (searchText:string) => ({ email:{$search: `${searchText}`} });

// const ShowTotal = () => {
//     const [refTotal, setRefTotal] = useState(0)
//     const { total, isLoading } = useListContext()

//     useEffect(() => {
//         setRefTotal(total)
//     }, [total])

//     return <FunctionField
//         render={() => refTotal}
//     />
// }

const RefBalance = () => {
    const record: IUser = useRecordContext();
    const { structure, loaded } = useStructure(record)
    const [bal, setBal] = useState(0)
    useEffect(() => {
        if (loaded) {
            setBal(structure.reduce((accumulator, level) => accumulator + level.data.reduce((accum, user) => accum + (user.balance || 0), 0), 0))
        }
    }, [loaded])
    return <FunctionField label='Баланс рефералов' render={() => bal} />

}

const EditToolbar = (props: ToolbarProps) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
            <DeleteWithConfirmButton
                confirmContent="You will not be able to recover this record. Are you sure?"
            />
        </Toolbar>
    )
};

export const UserList = () => {
    return (
        <List filters={postFilters} resource={RESOURCE} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source="promo" label="id" />
                <TextField source="email" />
                <FunctionField
                    label="Имя"
                    render={(record: any) => `${record.firstName} ${record.lastName}`}
                />;
                <ReferenceField link="show" label="Реферер" source="referrer" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <NumberField source='referrals' />
                <RefBalance />
                {/* <ReferenceManyField label="Рефералы" reference="users" target="referrer">
                    <ShowTotal />
                </ReferenceManyField> */}
                <SelectField source="rank" choices={RANKS} label="Звание" />
                <NumberField source="balance" label='Баланс' />
                <DateField source='createdAt' />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List >
    )
}

export const UserCreate = () => {
    return (
        <Create>
            <TabbedForm>
                <FormTab label="summary">
                    <TextInput disabled label="Id" source="id" fullWidth />
                    <TextInput source="email" />
                    <TextInput source="telegram" />
                    <ReferenceInput source="referrer" reference="users">
                        <SelectInput optionText="email" />
                    </ReferenceInput>
                </FormTab>
                <FormTab label="info">
                    <TextInput source="lastName" fullWidth />
                    <TextInput source="firstName" fullWidth />
                    <TextInput source="middleName" fullWidth />
                </FormTab>
                <FormTab label="finance">
                    <NumberInput source="balance" fullWidth />
                    <TextInput source="payeer" fullWidth />
                    <TextInput source="perfectMoney" fullWidth />
                    <TextInput source="tether" fullWidth />
                </FormTab>
                <FormTab label="settings">
                    <SelectInput fullWidth source="roles" choices={ROLES} />
                    <SelectInput fullWidth source="rank" choices={RANKS} />
                    <BooleanInput source="status" label="Activation" />
                    <BooleanInput source="emailNotification" />
                    <BooleanInput source="telegramNotification" />
                    <BooleanInput source="isTwoFactorEnabled" />
                </FormTab>
            </TabbedForm>
        </Create >
    )
}

export const UserEdit = () => {

    const tr: TransformData = async (data: any, options) => {

        Object.keys(data).map((key) => {
            if (data[key] === "")
                data[key] = undefined;
            if (data[key] === undefined && key === 'referrer')
                data[key] = null
        })

        console.log(data);
        return data;
    };

    return (
        <Edit transform={tr} >
            <TabbedForm toolbar={<EditToolbar />}>
                <FormTab label="summary">
                    <TextInput disabled label="Id" source="id" fullWidth />
                    <TextInput source="email" fullWidth />
                    <TextInput source="telegram" fullWidth />
                    <ReferenceInput source="referrer" reference="users" allowEmpty>
                        {/* <SelectInput optionText="email" /> */}
                        <AutocompleteInput optionText="email" filterToQuery={filterToQuery} fullWidth />
                    </ReferenceInput>

                </FormTab>
                <FormTab label="info">
                    <TextInput source="lastName" fullWidth />
                    <TextInput source="firstName" fullWidth />
                    <TextInput source="middleName" fullWidth />
                </FormTab>
                <FormTab label="finance">
                    <NumberInput source="balance" fullWidth />
                    <TextInput source="payeer" fullWidth />
                    <TextInput source="perfectMoney" fullWidth />
                    <TextInput source="tether" fullWidth />
                </FormTab>
                <FormTab label="settings">
                    <TextInput source="password" fullWidth />
                    <SelectInput fullWidth source="roles" choices={ROLES} emptyValue={''} />
                    <SelectInput fullWidth source="rank" choices={RANKS} emptyValue={''} />
                    <BooleanInput source="active" label="Подтверждение регистрации" />
                    <BooleanInput source="status" />
                    <BooleanInput source="emailNotification" />
                    <BooleanInput source="telegramNotification" />
                    <BooleanInput source="isTwoFactorEnabled" />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

const UserShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField label="Id" source="id" />
                    <TextField source="email" />
                    <TextField source="telegram" />
                    <ReferenceField link="show" source="referrer" label="Реферер" reference="users">
                        <TextField source="email" />
                    </ReferenceField>

                </Tab>
                <Tab label="info">
                    <TextField source="lastName" label="Фамилия" />
                    <TextField source="firstName" label="Имя" />
                    <TextField source="middleName" label="Отчество" />
                </Tab>
                <Tab label="finance">
                    <NumberField source="balance" label="Баланс" />
                    <TextField source="payeer" />
                    <TextField source="perfectMoney" />
                    <TextField source="tether" label="USDT TRC 20" />
                </Tab>
                <Tab label="settings">
                    <SelectField source="roles" choices={ROLES} label="Роли" />
                    <SelectField source="rank" choices={RANKS} label="Звание" />
                    <BooleanField source="status" label="Activation" />
                    <BooleanField source="emailNotification" label="Уведомлени на email " />
                    <BooleanField source="telegramNotification" label="Уведомлени в telegramN" />
                    <BooleanField source="isTwoFactorEnabled" label="2FA" />
                </Tab>
                <Tab label="referals">
                    <ReferenceManyField label="Рефералы" reference="users" target="referrer" pagination={<Pagination />}>
                        <Datagrid>
                            <TextField source="email" />
                            <SelectField source="rank" choices={RANKS} label="Звание" />
                            {/* <ReferenceManyField label="Рефералы" reference="users" target="referrer">
                                <ShowTotal />
                            </ReferenceManyField> */}
                            <TextField source="balance" />
                            <DateField source="createdAt" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="transactions">
                    <ReferenceManyField label="Транзакции" reference="transactions" target="user" pagination={<Pagination />}>
                        <Datagrid>
                            <SelectField source="transactionType" label='Тип транзакции' choices={TRANSACTION_TYPES} />
                            <NumberField source="amount" label='Сумма' />
                            <SelectField source="status" label='Статус' choices={TRANSACTION_STATUSES} />
                            <DateField source='createdAt' label='Дата' showTime />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="purchases">
                    <ReferenceManyField label="Покупки" reference="purchases" target="user" pagination={<Pagination />}>
                        <Datagrid>
                            <ReferenceField link="show" source='bundle' label='Бот' reference='bundles'>
                                <TextField source='title' />
                            </ReferenceField>
                            <TextField source='tradingAccount' label='Торговый счет' />
                            <DateField source='createdAt' label='Дата' showTime />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export const UserProps: ResourceProps = {
    name: 'users',
    icon: PersonIcon,
    list: UserList,
    edit: UserEdit,
    show: UserShow,
    create: UserCreate,
    options: {
        label: 'Пользователи'
    }
}
