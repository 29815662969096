import { Paginated } from '@feathersjs/feathers';
import { Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Box, TextField, Button, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ChangeEventHandler, useEffect, useState } from 'react';
import client from '../../helpers/feathers';
import { IIncome, IPurchase, IUser } from '../../interfaces/services';
import { useTranslation } from 'react-i18next';
import { API } from '../../constants';

interface IncomesTableProps {
    open: boolean,
    closeHandler: () => void
}

interface IncomeProps {
    purchase: IPurchase,
    update: (options: { purchase: string, amount: number }) => void
}

const IncomesTable = ({ open, closeHandler }: IncomesTableProps) => {
    const { t } = useTranslation()
    const [date, setDate] = useState<Date>(new Date())
    const [purchases, setPurchases] = useState<IPurchase[]>([])
    const [skip, setSkip] = useState(0)
    const [incomes, setIncomes] = useState<Paginated<IIncome>>()
    const [isSave, setIsSave] = useState(false)
    const [array, setArray] = useState<{ purchase: string, amount: number }[]>([])

    useEffect(() => {
        if (open)
            load()
    }, [open])

    useEffect(() => {
        console.log(skip)
        if (skip !== 0)
            load()
    }, [skip])


    const load = () => {

        fetch(`${API}/get-purchases`)
            .then(res => res.json())
            .then((result: Paginated<IPurchase>) => {
                setPurchases(result.data)
            })
    }

    // useEffect(() => {
    //     console.log(purchases.length - saved);

    //     if (saved === purchases.length && purchases.length !== 0)
    //         closeHandler()
    // }, [saved])

    const save = async () => {
        console.log(array);
        setIsSave(true)
        for (const item of array) {
            if (item.amount !== 0)
                await client.service('incomes').create({
                    purchase: item.purchase,
                    date,
                    amount: item.amount,
                })
        }
        closeHandler()
        // setTimeout(() => closeHandler(), purchases.length * 1001)
    }

    const update = (options: { purchase: string, amount: number }) => {
        const arr = [...array]
        const cur = arr.find(item => item.purchase === options.purchase)
        if (cur)
            cur.amount = options.amount
        else
            arr.push(options)
        setArray(arr)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle>{t('otchet')}</DialogTitle>
                <DialogContent>
                    <DatePicker
                        views={['year', 'month']}
                        label={t('perod')}
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue as Date);
                        }}
                        renderInput={(params) => <TextField sx={{ mt: '20px' }} {...params} />}
                    />
                    {isSave &&
                        <CircularProgress sx={{ position: 'fixed', top: '50%', left: '50%' }} />
                    }
                    <List>
                        {purchases.map((purchase, key) => (
                            <Income
                                purchase={purchase}
                                key={purchase._id}
                                update={update}
                            />
                        ))}
                    </List>

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler} style={{ color: "red" }}>Отменить</Button>
                    {!isSave &&
                        <Button onClick={save}>{t('sokhranit-1')}</Button>
                    }
                </DialogActions>
            </Dialog >
        </LocalizationProvider>
    )
}

const Income = ({ purchase, update }: IncomeProps) => {
    const [user, setUser] = useState<IUser>()
    const [value, setValue] = useState<string>('')
    const reg = /^[0-9]*[.,]?[0-9]*?$/

    useEffect(() => {
        if (value)
            update({ purchase: purchase._id, amount: Number(value.replace(',', '.')) })
    }, [value])

    useEffect(() => {
        client.service('users').get(purchase.user)
            .then((response: IUser) => setUser(response))
    }, [purchase])

    const handleInput: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (!event.target.value || reg.test(event.target.value))
            setValue(event.target.value)
    }

    const getName = (user: IUser) => {
        if (user.firstName || user.lastName)
            return user.firstName + ' ' + user.lastName
        else
            return user.email
    }

    return (
        <ListItem>
            <ListItemText sx={{ flex: 1 }} primary={user && getName(user)} />
            <ListItemText sx={{ flex: 1 }} primary={purchase.tradingAccount} />
            <Box sx={{ flex: 1 }}>
                <TextField
                    type="text"
                    size="small"
                    variant="standard"
                    value={value}
                    onChange={handleInput}
                />
            </Box>
        </ListItem >
    )
}

export default IncomesTable